/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Button, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kavárna MANETKA - Fotogalerie"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pt--60" name={"fotogalerie_uvod"} style={{"paddingBottom":39,"backgroundColor":"rgba(96, 96, 96, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1724,"paddingLeft":16,"paddingRight":2}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":1710,"marginBottom":1,"paddingBottom":0}}>
              
              <Title className="title-box fs--86" style={{"maxWidth":1588,"paddingLeft":0,"marginBottom":0,"paddingRight":0,"paddingBottom":0}} content={"<span style=\"color: var(--white);\">Fotogalerie kavárny a cukrárny</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--36" style={{"maxWidth":940,"paddingLeft":0,"marginBottom":23,"paddingRight":0,"paddingBottom":17}} content={"<span style=\"color: var(--white);\">Přátelé, ještě váháte s návštěvou naší kavárny?</span><br>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Prohlédněte si náš interiér i exteriér ve virtuální prohlídce a uvidíte, že se u nás budete cítit jako doma. <br>Těšíme se na Vaši návštěvu!</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--shape5 btn-box--cColor1 btn-box--shadow5" url={"https://www.google.com/maps/place/Manetka+cafe/@49.7800782,13.3664327,3a,90y,117.55h,89.06t/data=!3m7!1e1!3m5!1sAF1QipPbAylCirgJw9XZmzsz7O3ipPU8vZNmJViK7vnr!2e10!3e12!7i11000!8i5500!4m9!3m8!1s0x470af33bb34100a5:0x17c49104135d9431!8m2!3d49.7798029!4d13.3665938!10e5!14m1!1BCgIgARICCAI!16s%2Fg%2F11l2m45152?entry=ttu"} href={"https://www.google.com/maps/place/Manetka+cafe/@49.7800782,13.3664327,3a,90y,117.55h,89.06t/data=!3m7!1e1!3m5!1sAF1QipPbAylCirgJw9XZmzsz7O3ipPU8vZNmJViK7vnr!2e10!3e12!7i11000!8i5500!4m9!3m8!1s0x470af33bb34100a5:0x17c49104135d9431!8m2!3d49.7798029!4d13.3665938!10e5!14m1!1BCgIgARICCAI!16s%2Fg%2F11l2m45152?entry=ttu"} style={{"backgroundColor":"var(--color-blend--50)"}} content={"Virtuální prohlídka"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"foto_kavarna"} style={{"marginTop":0,"paddingTop":64,"paddingBottom":11,"backgroundColor":"rgba(96, 96, 96, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(255, 255, 255);\">fotografie areálu zahrady a terasy</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim4" anim={"4"} name={"zahrada"} style={{"paddingTop":11,"paddingBottom":53,"backgroundColor":"rgba(96, 96, 96, 1)"}} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center" style={{"paddingTop":25,"marginBottom":16,"paddingBottom":49}}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/b64e4b2937454c7d877a34be105611d8_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/34102/b64e4b2937454c7d877a34be105611d8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/b64e4b2937454c7d877a34be105611d8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/b64e4b2937454c7d877a34be105611d8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/b64e4b2937454c7d877a34be105611d8_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/b64e4b2937454c7d877a34be105611d8_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/668f5243df8c4c5fb8ffcaed9c9b8b8f_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/34102/668f5243df8c4c5fb8ffcaed9c9b8b8f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/668f5243df8c4c5fb8ffcaed9c9b8b8f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/668f5243df8c4c5fb8ffcaed9c9b8b8f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/668f5243df8c4c5fb8ffcaed9c9b8b8f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/668f5243df8c4c5fb8ffcaed9c9b8b8f_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/e5b8cbac09e245209b9b5a11da8d4705_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/34102/e5b8cbac09e245209b9b5a11da8d4705_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/e5b8cbac09e245209b9b5a11da8d4705_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/e5b8cbac09e245209b9b5a11da8d4705_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/e5b8cbac09e245209b9b5a11da8d4705_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/e5b8cbac09e245209b9b5a11da8d4705_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/8cb78c52e27849caa4f7840333e79bb5_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/34102/8cb78c52e27849caa4f7840333e79bb5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/8cb78c52e27849caa4f7840333e79bb5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/8cb78c52e27849caa4f7840333e79bb5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/8cb78c52e27849caa4f7840333e79bb5_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/8cb78c52e27849caa4f7840333e79bb5_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"foto_kavarna"} style={{"marginTop":0,"paddingTop":11,"paddingBottom":11,"backgroundColor":"rgba(96, 96, 96, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(255, 255, 255);\">fotografie kavárny</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"ko9eqg6nmgd"} style={{"paddingTop":16,"paddingBottom":5,"backgroundColor":"rgba(96, 96, 96, 1)"}} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"kavárenský bar"} src={"https://cdn.swbpg.com/t/34102/be42c77fc6ea4dd6b9dab7ae379e741b_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/34102/be42c77fc6ea4dd6b9dab7ae379e741b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/be42c77fc6ea4dd6b9dab7ae379e741b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/be42c77fc6ea4dd6b9dab7ae379e741b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/be42c77fc6ea4dd6b9dab7ae379e741b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/be42c77fc6ea4dd6b9dab7ae379e741b_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"0g46stqownpk"} style={{"paddingTop":60,"backgroundColor":"rgba(96, 96, 96, 1)"}} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/45d5152602904da8a41ec07f822e5cb6_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/34102/45d5152602904da8a41ec07f822e5cb6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/45d5152602904da8a41ec07f822e5cb6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/45d5152602904da8a41ec07f822e5cb6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/45d5152602904da8a41ec07f822e5cb6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/45d5152602904da8a41ec07f822e5cb6_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/433573be21a44b3ea0c6fef36b797a75_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/34102/433573be21a44b3ea0c6fef36b797a75_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/433573be21a44b3ea0c6fef36b797a75_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/433573be21a44b3ea0c6fef36b797a75_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/433573be21a44b3ea0c6fef36b797a75_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/433573be21a44b3ea0c6fef36b797a75_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/430227e75e5c4f75b836d0685e3a0f18_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/34102/430227e75e5c4f75b836d0685e3a0f18_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/430227e75e5c4f75b836d0685e3a0f18_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/430227e75e5c4f75b836d0685e3a0f18_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/430227e75e5c4f75b836d0685e3a0f18_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/430227e75e5c4f75b836d0685e3a0f18_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/02522fa1620e45449dcecb7809c04eea_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/34102/02522fa1620e45449dcecb7809c04eea_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/02522fa1620e45449dcecb7809c04eea_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/02522fa1620e45449dcecb7809c04eea_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/02522fa1620e45449dcecb7809c04eea_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/02522fa1620e45449dcecb7809c04eea_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"foto_akce"} style={{"marginTop":0,"paddingTop":11,"paddingBottom":11,"backgroundColor":"rgba(96, 96, 96, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(255, 255, 255);\">galerie našich akcí</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"fotoakce2"} style={{"paddingTop":31,"backgroundColor":"rgba(96, 96, 96, 1)"}} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/30e58fd65cf34b29a9d4a010b3b53b73_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/30e58fd65cf34b29a9d4a010b3b53b73_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/30e58fd65cf34b29a9d4a010b3b53b73_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/30e58fd65cf34b29a9d4a010b3b53b73_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/30e58fd65cf34b29a9d4a010b3b53b73_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/30e58fd65cf34b29a9d4a010b3b53b73_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/409cb51db1204d09b8a611ec42a91739_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/409cb51db1204d09b8a611ec42a91739_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/409cb51db1204d09b8a611ec42a91739_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/409cb51db1204d09b8a611ec42a91739_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/4aad47b75cd141fab6867368328e9147_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/4aad47b75cd141fab6867368328e9147_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/4aad47b75cd141fab6867368328e9147_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/4aad47b75cd141fab6867368328e9147_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/4aad47b75cd141fab6867368328e9147_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/4aad47b75cd141fab6867368328e9147_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/5f11709442b445aaa1162d01ac9631db_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/5f11709442b445aaa1162d01ac9631db_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/5f11709442b445aaa1162d01ac9631db_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/5f11709442b445aaa1162d01ac9631db_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/f8957d1cd558442394ef6ba732382214_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/f8957d1cd558442394ef6ba732382214_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/f8957d1cd558442394ef6ba732382214_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/f8957d1cd558442394ef6ba732382214_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/c426dcd204724e12aadd603c7f56be9f_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/c426dcd204724e12aadd603c7f56be9f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/c426dcd204724e12aadd603c7f56be9f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/c426dcd204724e12aadd603c7f56be9f_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/a7a606d3965c4073973723a8add65c4c_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/a7a606d3965c4073973723a8add65c4c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/a7a606d3965c4073973723a8add65c4c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/a7a606d3965c4073973723a8add65c4c_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/0e4e4678294b432ead182b70931325fb_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/0e4e4678294b432ead182b70931325fb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/0e4e4678294b432ead182b70931325fb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/0e4e4678294b432ead182b70931325fb_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/03ff6597e3124af0b21fb10180e1dffb_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/03ff6597e3124af0b21fb10180e1dffb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/03ff6597e3124af0b21fb10180e1dffb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/03ff6597e3124af0b21fb10180e1dffb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/03ff6597e3124af0b21fb10180e1dffb_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/f03f6db0245a4bd09f85d931a1aad678_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/f03f6db0245a4bd09f85d931a1aad678_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/f03f6db0245a4bd09f85d931a1aad678_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/f03f6db0245a4bd09f85d931a1aad678_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/f03f6db0245a4bd09f85d931a1aad678_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/f03f6db0245a4bd09f85d931a1aad678_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/11cb2c82ae0a4bc0a07c592da6a61c2a_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/11cb2c82ae0a4bc0a07c592da6a61c2a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/11cb2c82ae0a4bc0a07c592da6a61c2a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/11cb2c82ae0a4bc0a07c592da6a61c2a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/11cb2c82ae0a4bc0a07c592da6a61c2a_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/ad5afe31a24542088c6fd69b7bc335c4_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/ad5afe31a24542088c6fd69b7bc335c4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/ad5afe31a24542088c6fd69b7bc335c4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/ad5afe31a24542088c6fd69b7bc335c4_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/ecf7150dddb544e6834c8ea89ece6e50_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/ecf7150dddb544e6834c8ea89ece6e50_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/ecf7150dddb544e6834c8ea89ece6e50_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/ecf7150dddb544e6834c8ea89ece6e50_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/a1c30095c58648c8bacbd3c113ffb46d_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/a1c30095c58648c8bacbd3c113ffb46d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/a1c30095c58648c8bacbd3c113ffb46d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/a1c30095c58648c8bacbd3c113ffb46d_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/3f27020be1834a928ec0e558e5451107_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/3f27020be1834a928ec0e558e5451107_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/3f27020be1834a928ec0e558e5451107_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/3f27020be1834a928ec0e558e5451107_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/110d307a7efc436581c3009c1abe0c55_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/110d307a7efc436581c3009c1abe0c55_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/110d307a7efc436581c3009c1abe0c55_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/110d307a7efc436581c3009c1abe0c55_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"foto_kavarna"} style={{"marginTop":0,"paddingTop":11,"paddingBottom":11,"backgroundColor":"rgba(96, 96, 96, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(255, 255, 255);\">fotografie ostatní</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"dv5rg4nz7no"} style={{"paddingTop":31,"backgroundColor":"rgba(96, 96, 96, 1)"}} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/26bf39d4a9e44e17978f841ce47c1a40_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/26bf39d4a9e44e17978f841ce47c1a40_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/26bf39d4a9e44e17978f841ce47c1a40_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/26bf39d4a9e44e17978f841ce47c1a40_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/26bf39d4a9e44e17978f841ce47c1a40_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/26bf39d4a9e44e17978f841ce47c1a40_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/65bc72d22b0e419387ec9d2186b2c551_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/65bc72d22b0e419387ec9d2186b2c551_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/65bc72d22b0e419387ec9d2186b2c551_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/65bc72d22b0e419387ec9d2186b2c551_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/18b03b8db4dd428ab0e05f0a95835758_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/18b03b8db4dd428ab0e05f0a95835758_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/18b03b8db4dd428ab0e05f0a95835758_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/18b03b8db4dd428ab0e05f0a95835758_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/18b03b8db4dd428ab0e05f0a95835758_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/18b03b8db4dd428ab0e05f0a95835758_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/e296b910808743c8baa889bfd7fdf346_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/e296b910808743c8baa889bfd7fdf346_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/e296b910808743c8baa889bfd7fdf346_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/e296b910808743c8baa889bfd7fdf346_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/e296b910808743c8baa889bfd7fdf346_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/e296b910808743c8baa889bfd7fdf346_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/42cc9814631f4c418928ea9f50efaa8d_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/42cc9814631f4c418928ea9f50efaa8d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/42cc9814631f4c418928ea9f50efaa8d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/42cc9814631f4c418928ea9f50efaa8d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/42cc9814631f4c418928ea9f50efaa8d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/42cc9814631f4c418928ea9f50efaa8d_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/49cd0184de8948b68c511fda71ec4306_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/49cd0184de8948b68c511fda71ec4306_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/49cd0184de8948b68c511fda71ec4306_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/49cd0184de8948b68c511fda71ec4306_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/49cd0184de8948b68c511fda71ec4306_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/49cd0184de8948b68c511fda71ec4306_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":104,"paddingBottom":103}} name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" style={{"maxWidth":1406,"paddingLeft":0,"marginBottom":0}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">WEB</span>"}>
              </Title>

              <Text className="text-box fs--18 w--400 ls--002 lh--16" style={{"marginTop":14,"marginBottom":0,"paddingBottom":30}} content={"<span style=\"color: var(--black);\"><span style=\"text-decoration-line: underline;\"><a href=\"/#bcckfdbfejb\" style=\"\">O nás</a></span><a href=\"/menu#menu_uvod\"><br>Stálé menu<br></a><a href=\"/akce#j40igf0u8gp\">Akce</a><a href=\"/menu#menu_uvod\" style=\"\"><br></a><a href=\"/salonek#salonek_uvod\" style=\"\">Salónek</a><a href=\"/menu#menu_uvod\"><br></a><a href=\"/kontakt#kontakty\" style=\"\">Kontakt</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">KAVÁRNA A CUKRÁRNA MANETKA</span>"}>
              </Title>

              <Text className="text-box fs--18 w--400 ls--002 lh--16" style={{"maxWidth":396,"marginTop":13,"paddingRight":0,"paddingBottom":0}} content={"<span style=\"color: var(--black);\">Manětínská 30, Plzeň<br>tel: 773 300 833, email: kavarna@manetka.cz</span><br><span style=\"text-decoration-line: underline; font-weight: bold; color: var(--black);\"><a href=\"https://www.google.com/maps/place/Manetka+cafe/@49.7800782,13.3664327,3a,90y,117.55h,89.06t/data=!3m7!1e1!3m5!1sAF1QipPbAylCirgJw9XZmzsz7O3ipPU8vZNmJViK7vnr!2e10!3e12!7i11000!8i5500!4m9!3m8!1s0x470af33bb34100a5:0x17c49104135d9431!8m2!3d49.7798029!4d13.3665938!10e5!14m1!1BCgIgARICCAI!16s%2Fg%2F11l2m45152?entry=ttu\" target=\"_blank\" style=\"\">VIRTUÁLNÍ PROHLÍDKA KAVÁRNY</a></span><br><br><span style=\"font-weight: bold;\">OTEVÍRACÍ DOBA KAVÁRNY</span><br>Po - Čt&nbsp; 09:00 - 18:00, Pá 09:00 - 21:00<br>So 14:00 - 21:00, Ne&nbsp;14:00 - 18:00<br>"}>
              </Text>

              <Image style={{"maxWidth":124,"marginTop":33}} alt={""} src={"https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=860x_.png"} use={"page"} href={"/#bcckfdbfejb"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=350x_.png 350w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=660x_.png 660w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=860x_.png 860w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=1400x_.png 1400w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">SLEDUJ NÁS</span>"}>
              </Title>

              <Image style={{"maxWidth":64,"marginTop":31}} alt={"Facebook"} src={"https://cdn.swbpg.com/t/34102/556bd886db6346cc9a4b6ae94cde69b1_s=350x_.png"} url={"https://www.facebook.com/Manetkacafe"} use={"url"} href={"https://www.facebook.com/Manetkacafe"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/556bd886db6346cc9a4b6ae94cde69b1_s=350x_.png 350w"} lightbox={false}>
              </Image>

              <Image style={{"maxWidth":64,"marginTop":35}} alt={"Instagram"} src={"https://cdn.swbpg.com/t/34102/9fa843421abe4a449de7c1ad3493add4_s=350x_.png"} url={"https://www.instagram.com/manetka_cafe/"} use={"url"} href={"https://www.instagram.com/manetka_cafe/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/9fa843421abe4a449de7c1ad3493add4_s=350x_.png 350w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}